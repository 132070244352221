























































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { SetOfDocuments } from "@/models/contract";
import { OrganizationConsumption } from "@/models/organization";
import XDropdownWithDatePicker from "../hoc/DropdownWithDatePicker.vue";
import XDropdownWithRadioButtons from "../hoc/DropdownWithRadioButtons.vue";
import XButton from "../SimpleButton.vue";
import AnalysisTable from "./AnalysisTable.vue";
import ObjectListCard from "./ObjectListCard.vue";
import AccountingPointTable from "./AccountingPointTable.vue";
import XSlider from "../SimpleSlider.vue";
import ConsumedChart from "./ConsumedChart.vue";
import AppApiMixin from "../mixins/AppApi.vue";

@Component({
  components: {
    XDropdownWithDatePicker,
    XDropdownWithRadioButtons,
    XButton,
    AnalysisTable,
    ObjectListCard,
    AccountingPointTable,
    XSlider,
    ConsumedChart,
  },
  computed: {
    ...mapGetters({
      contractId: "contract/id",
      sortedPointsByObject: "accountingPoints/sortedPointsByObject",
    }),
  },
})
class AnalysisTabContent extends Mixins(AppApiMixin) {
  dropdownGroupCssClasses = [
    "tabs__dropdown-group",
    "box",
    "box_shadow_initial",
    "px-6",
    "py-2",
    "rounded-lg",
    "white",
  ];

  // Свойства, использующиеся в блоке управления страницей.
  // (прим: расположены по порядку элементов).
  dates: string[] = [];

  sliceTypes = ["По договору", "По объекту", "По точке учета"];
  currentSliceTypeIndex = 0;
  currentSliceId = 0;

  services: SetOfDocuments[] = [];
  serviceTypes: string[] = [];

  currentServiceTypeIndex = 0;
  currentServiceTypeUnit = "";

  // Потребление.
  consumptionList: OrganizationConsumption[] = [];

  // Слайдер и его дочерние элементы.
  showConsumptionDetails = false;
  showSliderPreloader = false;
  captionForSliderTable = "";

  public get currentSliceType(): string {
    return this.sliceTypes[this.currentSliceTypeIndex];
  }

  public get currentServiceType(): string {
    return this.serviceTypes[this.currentServiceTypeIndex] || "Услуга";
  }

  public get sliderTitle(): string {
    const titleEnd =
      this.currentSliceTypeIndex === 1 ? "объекту" : "точке учета";
    return `Анализ потребления по ${titleEnd}`;
  }

  @Watch("currentSliceTypeIndex")
  public currentSliceTypeIndexChanged(newState: number) {
    if (!newState) {
      this.currentSliceId = this.contractId;
    }
  }

  public mounted() {
    const { contractId } = this;

    this.getServicesByContract(contractId, (services: SetOfDocuments[]) => {
      this.services = services;
      this.serviceTypes = services.map((service) => service["значение"]);
      this.currentSliceId = contractId;
    });
  }

  public onUpdateSliceId(sliceId: number) {
    if (this.currentSliceId === sliceId) {
      this.showSliderPreloader = false;
      return;
    }

    this.currentSliceId = sliceId;
    this.updateTable();
  }

  public updateTable() {
    const {
      contractId,
      currentServiceTypeIndex,
      currentSliceTypeIndex: type,
      currentSliceId: objId,
      dates,
      getConsumptionList,
      services,
    } = this;

    const [from, to] = dates;

    const payload = {
      contractId,
      objId,
      type,
      service: services[currentServiceTypeIndex]["ключ"],
      from,
      to,
    };

    getConsumptionList(
      payload,
      (consumptionList: OrganizationConsumption[]) => {
        const currentService = services[currentServiceTypeIndex];

        this.currentServiceTypeUnit = currentService
          ? currentService["описание"]
          : "";
        this.consumptionList = consumptionList;
      }
    ).finally(() => {
      this.showSliderPreloader = false;
    });
  }
}

export default AnalysisTabContent;
