var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-card',{staticClass:"card_type_consumption-objects"},[_c('ul',{staticClass:"card__rows pl-0"},[_vm._l((_vm.pointsByObjects),function(pointsByObject,i){return [_c('v-row',{key:("a-" + i),staticClass:"card__row align-center",attrs:{"tag":"li"},on:{"click":function () {
            var objectId = pointsByObject.objectId;
            var objectName = pointsByObject.objectName;

            _vm.$emit('update:sliderPreloader', true);
            _vm.$emit('update:slider', true);
            _vm.$emit('update:tableCaption', objectName || 'Без привязки');
            _vm.$emit('update:sliceId', objectId);
          }}},[_vm._v(" "+_vm._s(pointsByObject.objectName || "Без привязки")+" ")]),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(i < _vm.pointsByObjects.length),expression:"i < pointsByObjects.length"}],key:("b-" + i)})]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }