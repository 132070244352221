var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-table',_vm._b({staticClass:"data-table_as_tile",attrs:{"headers":_vm.preparedHeaders,"items":_vm.preparedPoints,"item-class":"text-body-1","caption":_vm.points.objectName || 'Без привязки'},on:{"click:row":function (ref, ref$1) {
      var number = ref.number;
      var index = ref$1.index;

      _vm.$emit('update:sliderPreloader', true);
      _vm.$emit('update:slider', true);
      _vm.$emit('update:tableCaption', ("Точка учета № " + number));
      _vm.$emit(
        'update:sliceId',
        _vm.points.pointList.find(function (_, i) { return i === index; })['лицевой']
      );
    }}},'x-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }