





















































import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import XDropdown from "../SimpleDropdown.vue";

@Component({
  components: { XDropdown },
})
class DropdownWithCheckboxes extends XDropdown {
  @Prop({ default: () => [] }) readonly checkboxes!: boolean[];

  @Prop({ default: "Меню" }) readonly activatorText!: string;
  @Prop({ default: "Все" }) readonly checkboxTypeAllText!: string;
  @Prop({ default: "" }) readonly listTitleText!: string;

  selectedAll = true;
  selectedCheckboxes: boolean[] = [];

  public get isAloneSelectedCheckbox(): boolean {
    return (
      this.selectedCheckboxes.indexOf(true) ===
      this.selectedCheckboxes.lastIndexOf(true)
    );
  }

  @Emit("click")
  emitUpdateCheckboxesEvt(): void {
    this.$emit("update:checkboxes", this.selectedCheckboxes);
  }

  mounted() {
    this.updateCheckboxes();
  }

  public updateCheckboxes() {
    const checkboxesLength = this.checkboxes.length;

    if (!checkboxesLength || checkboxesLength < this.items.length) {
      this.selectedCheckboxes = this.items.map(() => true);
    } else {
      this.selectedAll = !this.checkboxes.some((checkbox) => !checkbox);
      this.selectedCheckboxes = [...this.checkboxes];
    }

    this.emitUpdateCheckboxesEvt();
  }

  updated() {
    this.updateCheckboxes();
  }

  public checkedAll() {
    this.selectedCheckboxes = this.selectedCheckboxes.map(() => true);
    this.emitUpdateCheckboxesEvt();
  }

  public onCheckbox() {
    this.selectedAll = !this.selectedCheckboxes.some((checkbox) => !checkbox);
    this.emitUpdateCheckboxesEvt();
  }
}

export default DropdownWithCheckboxes;
