























































import { OrganizationReport } from "@/models/organization";
import { Component, Mixins } from "vue-property-decorator";
import { mapGetters } from "vuex";
import DocGetterCard from "./DocGetterCard.vue";
import DocGetterDialog from "./DocGetterDialog.vue";
import XNotice from "../hoc/SimpleNotice.vue";
import AppApiMixin from "../mixins/AppApi.vue";

@Component({
  components: { DocGetterCard, DocGetterDialog, XNotice },
  computed: { ...mapGetters("contract", { contractId: "id" }) },
})
class ReportsTabContent extends Mixins(AppApiMixin) {
  [x: string]: any;

  reports: OrganizationReport[] = [];
  currentReportIndex = -1;

  // Состояния всплывающих элементов страницы.
  showDocSendingDialog = false;
  useDocSendingDialogNotice = false;
  targetEmail = "";

  showDocDownloadingDialog = false;

  public get docGetterCardTitles(): string[] {
    return this.reports.map((report) => report["название"]);
  }

  public get selectedReport(): OrganizationReport | null {
    return this.reports.find((_, i) => i === this.currentReportIndex) || null;
  }

  public mounted() {
    this.getOrganizationReports(
      this.contractId,
      (fetchedReports: OrganizationReport[]) => {
        this.reports = fetchedReports;
      }
    );
  }

  public closeAllDialogs() {
    this.showDocDownloadingDialog = false;
    this.showDocSendingDialog = false;

    this.currentReportIndex = -1;
    this.targetEmail = "";
  }
}

export default ReportsTabContent;
