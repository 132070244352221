






















import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { OrganizationConsumption } from "@/models/organization";
import { formatDate } from "@/lib/date";
import { prepareMoney } from "@/assets/scripts/utils";
import XDataTable from "../hoc/Table.vue";

@Component({
  components: { XDataTable },
  computed: {
    ...mapState({ contract: "contract" }),
  },
})
class AnalysisTable extends Vue {
  [x: string]: any;

  @Prop({ required: true })
  readonly consumptionList!: OrganizationConsumption[];
  @Prop({ default: "" }) readonly caption!: string;
  @Prop({ default: "" }) readonly consumingUnit!: string;

  search = "";

  public get preparedCaption(): string {
    const { caption, contract } = this;

    if (caption) return caption;

    if (contract) return `Договор № ${contract.contract["номер"]}`;

    return "Анализ потребления";
  }

  public get preparedHeaders() {
    const consumedHeaderText = ["Потреблено", this.consumingUnit]
      .join(", ")
      .trimEnd();

    return [
      { text: "Месяц", value: "month" },
      { text: consumedHeaderText, value: "consumed", width: "25%" },
      {
        text: "Начислено",
        value: "accrued",
        width: "55%",
        class: "data-table__text_type_money",
      },
    ];
  }

  public get preparedConsumptionList(): Array<string[]> {
    const { consumptionList } = this;

    if (!consumptionList.length) {
      return [];
    }

    const consumptionKeys = ["месяц", "объем", "сумма"];

    return consumptionList
      .slice()
      .reverse()
      .map((consumptionByMonth: OrganizationConsumption) =>
        consumptionKeys.map((key, i) => {
          const tdValue = consumptionByMonth[key];

          return i
            ? prepareMoney(tdValue)
            : formatDate(tdValue, "monthAndYear");
        })
      );
  }
}

export default AnalysisTable;
