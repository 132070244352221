






















import { Component, Prop, Vue } from "vue-property-decorator";
import { AccountingPointsByObject } from "@/models/accounting-point";
import XDataTable from "../hoc/Table.vue";

@Component({
  components: { XDataTable },
})
class AccountingPointTable extends Vue {
  [x: string]: any;

  @Prop({ required: true }) readonly points!: AccountingPointsByObject;

  headers = [
    { text: "Номер", value: "number", width: "10%" },
    { text: "Наименование", value: "name", width: "15%" },
    { text: "Адрес", value: "address", width: "15%" },
    { text: "Тип ПУ", value: "type", width: "10%" },
    { text: "Заводской номер", value: "id", width: "15%" },
    { text: "Ценовая категория", value: "priceCategory", width: "10%" },
    { text: "Уровень напряжения", value: "voltageLevel", width: "10%" },
    { text: "Группа (Форма 46)", value: "group", width: "15%" },
  ];

  pointKeys = [
    "номер",
    "названиету",
    "адрес",
    "типсчетчика",
    "заводскойномер",
    "цкатегория",
    "уровеньнапряжения",
    "аналитика46",
  ];

  public get totalUsedPointKeys(): number {
    return this.contractType === 3 ? this.pointKeys.length : 3;
  }

  public get preparedHeaders() {
    return this.headers.slice(0, this.totalUsedPointKeys);
  }

  public get preparedPoints(): Array<string[]> {
    const points = this.points.pointList;
    const pointKeys = this.pointKeys.slice(0, this.totalUsedPointKeys);

    return points.map((point) => pointKeys.map((key) => point[key]));
  }
}

export default AccountingPointTable;
