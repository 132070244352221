




































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { formatDate } from "@/lib/date";
import { Contract, ContractsByOrganization } from "@/models/contract";
import {
  OrganizationAnalysts,
  OrganizationTurnovers,
  OrganizationTurnoversDetails,
} from "@/models/organization";
// Компоненты
import XDropdownWithDatePicker from "../hoc/DropdownWithDatePicker.vue";
import XDropdownWithCheckboxes from "../hoc/DropdownWithCheckboxes.vue";
import ContractIcon from "../ContractIcon.vue";
import XDropdownWithRadioButtons from "../hoc/DropdownWithRadioButtons.vue";
import XButton from "../SimpleButton.vue";
import TurnoversTable from "./TurnoversTable.vue";
import PaymentChart from "./PaymentChart.vue";
import XSlider from "../SimpleSlider.vue";
import SaldoTable from "./SaldoTable.vue";
import PrepaymentTable from "./PrepaymentTable.vue";
import InvoiceTable from "./InvoiceTable.vue";
import PaymentTable from "./PaymentTable.vue";
import AppApiMixin from "../mixins/AppApi.vue";

@Component({
  components: {
    XDropdownWithDatePicker,
    XDropdownWithCheckboxes,
    ContractIcon,
    XDropdownWithRadioButtons,
    XButton,
    TurnoversTable,
    PaymentChart,
    XSlider,
    SaldoTable,
    PrepaymentTable,
    InvoiceTable,
    PaymentTable,
  },
  computed: {
    ...mapGetters({
      sortedContractsByOrganization: "contracts/sortedContractsByOrganization",
      companyId: "organization/id",
    }),
  },
})
class TurnoversTabContent extends Mixins(AppApiMixin) {
  dropdownGroupCssClasses = [
    "tabs__dropdown-group",
    "box",
    "box_shadow_initial",
    "px-6",
    "py-2",
    "rounded-lg",
    "white",
  ];

  // Свойства, использующиеся в блоке управления страницей.
  // (прим: расположены по порядку элементов).
  dates: string[] = [];

  contractDropdownTitle = "Учитываемые договоры";
  selectedContracts: boolean[] = [];

  activityDropdownItems = [
    { label: "Все", useDivider: true },
    "Основные",
    "Прочие",
  ];
  selectedActivity = 1;

  analysts: OrganizationAnalysts[] = [];
  selectedAnalytics: boolean[] = [];

  // Обороты
  turnoversList: OrganizationTurnovers[] = [];

  // Интерактивные компоненты
  showDetails = false;
  showDialogPreloader = false;

  selectedMonth = "";
  detailsForTables: OrganizationTurnoversDetails[] = [];

  public get companyContracts(): Contract[] {
    const { sortedContractsByOrganization, companyId } = this;

    const contractsByOrganization: ContractsByOrganization =
      sortedContractsByOrganization.find(
        (contractsByOrganization: ContractsByOrganization) =>
          contractsByOrganization.companyId === companyId
      );

    return contractsByOrganization.contractList || [];
  }

  // Вычислямые свойства, использующиеся для настройки дропдаунов.
  public get preparedContractDropdownItems(): string[] {
    return this.companyContracts.map((contract: Contract) => {
      const contractStartDate = formatDate(contract["начало договора"], "full");
      const contractNumber = contract["номер"];

      return `№ ${contractNumber} от ${contractStartDate}`;
    });
  }

  public get activityDropdownActivatorText(): string {
    const textStarts = ["Все", "Основные", "Прочие"];

    return `${textStarts[this.selectedActivity]} виды деятельности`;
  }

  public get analyticsDropdownItems(): string[] {
    return this.analysts.map((analysts) => analysts["название"]);
  }

  // Вычисляемые свойства, использующиеся в теле запроса на получение оборотов.
  public get selectedContractIDs(): string {
    const { companyContracts, selectedContracts } = this;

    return companyContracts
      .filter((_: Contract, i: number) => selectedContracts[i])
      .map((item: Contract) => item["$номерЗаписи"])
      .join(",");
  }

  public get selectedAnalyticsCode(): string {
    const { analysts, selectedActivity, selectedAnalytics } = this;

    if (!selectedActivity) {
      return analysts.map((item) => item["код"]).join(",");
    }

    if (selectedActivity === 1) {
      return "";
    }

    return analysts
      .filter((_, i) => selectedAnalytics[i])
      .map((item) => item["код"])
      .join(",");
  }

  public get dialogSubtitle(): string {
    return formatDate(this.selectedMonth, "monthAndYear");
  }

  @Watch("selectedMonth")
  public monthChanged(nextState: string) {
    if (nextState) {
      this.showMonthlyTurnoversDetails();
    }
  }

  public mounted() {
    this.getAnalysts((analysts: OrganizationAnalysts[]) => {
      this.analysts = analysts;
      this.selectedAnalytics = analysts.map(() => true);
    });
    this.updateTable();
  }

  public updateTable() {
    const {
      dates,
      getOrganizationTurnovers,
      selectedActivity,
      selectedAnalyticsCode,
      selectedContractIDs,
    } = this;

    const [from, to] = dates;

    const payload = {
      contractId: selectedContractIDs,
      activities: selectedActivity,
      analysts: selectedAnalyticsCode,
      from,
      to,
    };

    getOrganizationTurnovers(payload, (turnovers: OrganizationTurnovers[]) => {
      this.turnoversList = turnovers;
    });
  }

  public showMonthlyTurnoversDetails() {
    const {
      selectedActivity,
      selectedAnalyticsCode,
      selectedContractIDs,
      selectedMonth,
    } = this;

    const turnoversTypes = [
      "incoming",
      "prepayment",
      "invoices",
      "payments",
      "outgoing",
    ];

    const payloads = turnoversTypes.map((type) => ({
      contractId: selectedContractIDs,
      activities: selectedActivity,
      analysts: selectedAnalyticsCode,
      month: selectedMonth,
      type,
    }));

    this.showDetails = true;
    this.showDialogPreloader = true;

    this.getOrganizationTurnoversDetails(
      payloads,
      (turnoversDetails: OrganizationTurnoversDetails[]) => {
        this.detailsForTables = turnoversDetails;
      }
    ).finally(() => {
      this.showDialogPreloader = false;
    });
  }

  /*
   * Использовать методы getTypeOfConsumption и getIconCssClass для стилизации дропдауна,
   * содержащего список договоров.
   */
  public getTypeOfConsumption(index: number): string {
    return this.companyContracts[index]["видпотребления"];
  }

  public getIconCssClass(index: number): string {
    const colors = ["primary", "warning", "info", "primary"];

    return `${colors[index - 1]}--text`;
  }
}

export default TurnoversTabContent;
