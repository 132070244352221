var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs__content tabs__content_with_doc-getter"},[_vm._l((_vm.docGetterCardTitles),function(title,i){return _c('doc-getter-card',{key:i,attrs:{"title":title},on:{"download":function () {
        _vm.currentReportIndex = i;
        _vm.showDocDownloadingDialog = true;
      },"send":function () {
        _vm.currentReportIndex = i;
        _vm.showDocSendingDialog = true;
      }}})}),_c('doc-getter-dialog',{attrs:{"show-dialog":_vm.showDocSendingDialog,"is-send-mode":true,"report":_vm.selectedReport},on:{"agree":function (ref) {
        var email = ref.payload;

        _vm.closeAllDialogs();
        _vm.targetEmail = email;
        _vm.useDocSendingDialogNotice = !_vm.useDocSendingDialogNotice;
      },"disagree":_vm.closeAllDialogs}}),_c('doc-getter-dialog',{attrs:{"show-dialog":_vm.showDocDownloadingDialog,"report":_vm.selectedReport},on:{"agree":_vm.closeAllDialogs,"disagree":_vm.closeAllDialogs}}),_c('x-notice',{attrs:{"show":_vm.useDocSendingDialogNotice}},[_vm._v(" Документы успешно отправлены на email: "+_vm._s(_vm.targetEmail)+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }