









import { Component, Prop, Vue } from "vue-property-decorator";
import { prepareMoney } from "@/assets/scripts/utils";
import { formatDate } from "@/lib/date";
import { OrganizationTurnoversDetails } from "@/models/organization";
import XDataTable from "../hoc/Table.vue";

@Component({
  components: { XDataTable },
})
class SaldoTable extends Vue {
  @Prop({ default: true }) readonly isIncoming!: boolean;
  @Prop({ default: () => [] }) readonly items!: OrganizationTurnoversDetails[];

  headers = [
    { text: "Тип документа", value: "docType" },
    { text: "Дата", value: "docDate" },
    { text: "Номер", value: "docNumber" },
    { text: "Сумма с НДС", value: "amountWithTax", align: "end" },
    { text: "Сумма без НДС", value: "amountWithoutTax", align: "end" },
    { text: "Вид деятельности", value: "activityType" },
    { text: "Вид задолженности", value: "indebtednessType" },
    { text: "Сумма документа", value: "docAmount", align: "end" },
  ];

  public get caption(): string {
    return `${this.isIncoming ? "Входящее" : "Исходящее"} сальдо`;
  }

  public get preparedItems() {
    const requiredKeys = [
      "тип",
      "дата",
      "номер",
      "суммасндс",
      "суммабезндс",
      "аналитика",
      "видзадолженности",
      "суммадокумента",
    ];

    return this.items.map((item) =>
      requiredKeys.map((key, i) => {
        const value = item[key];

        if (i === 1) return formatDate(value, "full");

        if (
          i === 4 &&
          ["Платеж входящий", "Кассовый ордер"].includes(item["тип"])
        ) {
          return "";
        }

        if ([3, 4, 7].includes(i)) return prepareMoney(value);

        return value;
      })
    );
  }
}

export default SaldoTable;
